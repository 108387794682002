<template>
  <div rounded="7.5" flex="~ items-center">
    <BaseAZPopover v-model:open="showCityPopup" w="full" :show-close-icon="false" align="start" :align-offset="alignOffset" :side-offset="sideOffset">
      <template #reference>
        <slot :select-city-name="selectCityName">
          <div v-bind="$attrs" flex="~ items-center" p="x-3 y-4">
            <div w="8">
              <NuxtIcon :name="selectCityName ? 'my-icon:location' : 'my-icon:search'" text="3xl #8D8D8D" class="align-middle"></NuxtIcon>
            </div>
            <div m="l-3.5" flex="1" text="left">
              <span v-if="selectCityName" text="lg black" font="500">{{ selectCityName }}</span>
              <span v-else text="lg #B1B1B1">{{ t('key.home.search.tips') }}</span>
            </div>
            <NuxtIcon v-if="selectCityName" name="my-icon:close" text="base" @click.stop="filterConditionStore.filterConditions.selectedCity = {}"></NuxtIcon>
          </div>
        </slot>
      </template>
      <DesktopSearchCity :refrehs-on-confirm="false" :page-type="pageType" @confirm="showCityPopup = false"></DesktopSearchCity>
    </BaseAZPopover>
  </div>
</template>

<script setup lang="ts">
defineProps({
  alignOffset: {
    type: Number,
    default: 5,
  },
  sideOffset: {
    type: Number,
    default: 5,
  },
  refrehsOnConfirm: {
    type: Boolean,
    default: false,
  },
  pageType: {
    type: String,
    default: '',
  },
})

const { t } = useI18n()
const filterConditionStore = useFilterConditionsStore()
const { showCityPopup } = useUseHomePopup()

const selectCityName = computed(() => {
  return filterConditionStore.filterConditions.selectedCity?.destinationName
})
</script>

<style lang="scss" scoped>
.city-item {
  cursor: pointer;
  &:hover {
    color: var(--tc-color-primary1);
  }
}
</style>
