<template>
  <div w="full" mx="auto" h="full" class="absolute" flex="~ col" bg="#F0F0F0">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import Intercom from '@intercom/messenger-js-sdk'

onMounted(() => {
  Intercom({
    app_id: 'iy1e1izd',
    vertical_padding: 60,
    horizontal_padding: 40,
    language_override: 'en',
    custom_launcher_selector: '#cs-launcher',
    hide_default_launcher: true,
  })
})

if (import.meta.client) {
  // Fix the issue of 300ms delay in mobile browser
  document.addEventListener('touchstart', () => {}, false)
}

const { locale } = useLocale()
const userStore = useUserStore()

locale.value = 'en-US'
userStore.userInfo.currency = 'USD'
</script>

<style scoped>
#__nuxt {
  height: 100%;
  background: #ffffff;
}
</style>
