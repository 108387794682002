/**
 * 自动生成的代码，请勿修改
 */
import { doRequest } from './utils';
import type { ResponseType } from './utils';
import type {
  ListSupportCurrencyRequest,
  ResponseListSupportCurrencyResponse,
  ChangeCurrencyRequest,
  Response,
} from '../types';

const baseUrl = '';

class CurrencyControllerService {
  /**
   * @summary 获取支持的币种*
   * @fullUrl /currency/list
   */
  public currencyListPost(
    body: ListSupportCurrencyRequest,
  ): ResponseType<ResponseListSupportCurrencyResponse> {
    const requestUrl = baseUrl + '/currency/list';
    const res = doRequest<ResponseListSupportCurrencyResponse>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary 更改当前的币种*
   * @fullUrl /currency/change
   */
  public currencyChangePost(
    body: ChangeCurrencyRequest,
  ): ResponseType<Response> {
    const requestUrl = baseUrl + '/currency/change';
    const res = doRequest<Response>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
}

export const currencyController = new CurrencyControllerService();
