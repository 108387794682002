<template>
  <div p="x-6 t-8 b-6" bg="black">
    <div max="w-260" m="x-auto">
      <div flex="~ justify-between">
        <div class="text-base text-white">
          <div font="600" m="b-4">
            {{ t('key.footer.company') }}
          </div>
          <ul class="space-y-4" text="sm" font="400">
            <li v-for="link in aboutLinks" :key="link.text">
              <NuxtLink :to="link.url">
                <span class="text-white underline">{{ link.text }}</span>
              </NuxtLink>
            </li>
          </ul>
        </div>
        <div class="text-base text-white">
          <div font="600" m="b-4">
            {{ t('key.footer.help') }}
          </div>
          <ul class="space-y-4" text="sm" font="400">
            <li>
              <NuxtLink to="https://corporate.azgotrip.com/contact-us" class="text-gray-700 hover:text-gray-900">
                <span class="text-white underline">{{ t('key.footer.contact.us') }}</span>
              </NuxtLink>
            </li>
            <li v-if="!isBurnCoinsScene">
              <NuxtLink to="https://corporate.azgotrip.com/faq" target="_self" class="text-gray-700 hover:text-gray-900">
                <span class="text-white underline">{{ t('key.footer.faqs') }}</span>
              </NuxtLink>
            </li>
          </ul>
        </div>
        <div class="text-base text-white">
          <div font="600" m="b-4">
            {{ t('key.footer.partnerships') }}
          </div>
          <ul class="space-y-4" text="sm" font="400">
            <li v-for="link in partnershipsLinks" :key="link.text">
              <NuxtLink :to="link.url" class="text-gray-700 hover:text-gray-900">
                <span class="text-white underline">{{ link.text }}</span>
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>

      <div m="b-10 t-5" class="h-1px bg-[#B3B3B3]"></div>

      <!-- Footer Section -->
      <footer class="mb-4 mt-8" text="sm center white" font="400">
        <div class="mb-2">
          <NuxtLink to="https://corporate.azgotrip.com/terms-of-use" :external="true">
            <span class="text-white underline">{{ t('key.footer.terms') }}</span>
          </NuxtLink>
          <van-divider vertical />
          <NuxtLink to="https://corporate.azgotrip.com/privacy-policy" :external="true">
            <span class="text-white underline">{{ t('key.footer.privacy.policy') }}</span>
          </NuxtLink>
        </div>
        <div>
          <p>{{ t('key.footer.copyright') }}</p>
        </div>
      </footer>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const { isBurnCoinsScene } = useGlobalStore()

const aboutLinks = computed(() => [
  { text: t('key.footer.about.us'), url: 'https://corporate.azgotrip.com/about-us' },
  { text: t('key.footer.products'), url: 'https://corporate.azgotrip.com/products' },
  { text: t('key.footer.press'), url: 'https://corporate.azgotrip.com/press-media-coverage' },
])

const partnershipsLinks = computed(() => [
  { text: t('key.footer.solutions'), url: 'https://corporate.azgotrip.com/solutions' },
  { text: t('key.footer.become.partner'), url: 'https://corporate.azgotrip.com/partnership-enquiry' },
  { text: t('key.footer.become.distributor'), url: 'https://corporate.azgotrip.com/affiliate-program' },
])
</script>

<style lang="scss">
.az-collapse {
  .van-cell {
    padding: 10px 0;
  }

  .van-collapse-item {
    &__content {
      padding: 0 0 12px 0;
    }

    &__title--expanded:after {
      display: none;
    }

    &--border:after {
      left: 0;
      right: 0;
    }
  }
}
</style>
