<script setup lang="ts">
import 'vant/lib/index.css'

const { watchLocale, locale, cookie, changeLocale } = useLocale()
watchLocale()

useHead({
  script: [
    {
      src: useRuntimeConfig().public.NUXT_TRACK_SCRIPT_URL,
      async: true,
    },
  ],
})

const route = useRoute()
const isMobile = useMobileByScreen()
const commonStore = useCommonStore()

const { isBurnCoinsScene } = useGlobalStore()

// 如果cookie中没有locale，就设置分销商配置的默认locale
if (!cookie.value
  && commonStore.commonData.partnerInfo?.defaultLocale
  && commonStore.commonData.partnerInfo?.defaultLocale !== locale.value) {
  changeLocale(commonStore.commonData.partnerInfo?.defaultLocale)
}

// 根据路由判断当前页面的布局
const layout = computed(() => {
  if (route.path.startsWith('/hotel')) {
    return 'hotel'
  }
  if (route.path.startsWith('/mk_lp')) {
    return isMobile.value ? 'mobile' : 'desktop'
  }
  return isBurnCoinsScene ? 'coins' : 'default'
})

onMounted(() => {
  window.dispatchEvent(new Event('resize'))
})

watch(() => locale.value, () => {
  commonStore.updateInfoByLocale()
  commonStore.getCityList()
})
</script>

<template>
  <NuxtLoadingIndicator />
  <NuxtRouteAnnouncer />
  <NuxtLayout :name="layout">
    <NuxtPage />
  </NuxtLayout>
</template>

<style>
:root:root {
  --van-popup-close-icon-color: #121212;
  --van-base-font: 'LexendDeca', sans-serif;
  --van-picker-option-padding: 0.625rem;
  --van-tag-radius: 0.25rem;
}
</style>
