export function useCommonHead() {
  const headers = useRequestHeaders(['x-forwarded-for', 'x-real-ip'])

  const userIp = useState('userIp', () => headers['x-forwarded-for'] || headers['x-real-ip'])
  const userAgent = import.meta.client ? navigator.userAgent : useRequestHeader('user-agent') || ''

  const { locale } = useLocale()
  const isMobile = useMobileDevice()
  const userStore = useUserStore()

  return {
    locale: locale.value,
    currency: userStore.userInfo.currency,
    channel: isMobile ? 'H5' : 'PC',
    userIp: userIp.value,
    userAgent,
  }
}
