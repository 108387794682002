<template>
  <div flex="~ items-center justify-between" p="x-4 y-2">
    <div flex="~ items-center" font="500" text="xs white">
      <template v-if="isPartner">
        <NuxtLink :to="cobrand.logoLink || { path: `/${locale}`, query }" :external="!!cobrand.logoLink" target="_blank" rel="noopener noreferrer">
          <img v-if="cobrand.logo" :src="cobrand.logo?.replace(/^http:\/\//i, 'https://')" class="max-w-25 object-contain align-middle" h="6" />
          <span v-else-if="cobrand.name" class="text-white md:ml-2 md:text-lg">{{ cobrand.name }}</span>
        </NuxtLink>
        <template v-if="partnerInfo?.showPoweredText">
          <span mx="1.5 md:4" w="1px" bg="white" h="4 md:6"></span>
          <span class="md:text-sm" mr="1 md:2">{{ t('key.business.user.header.poweredBy') }}</span>
        </template>
      </template>
      <NuxtLink :to="{ path: `/${locale}`, query }">
        <img v-if="!isPartner" w="20 md:30" self="end md:center" class="mt--1" src="~assets/icons/azgoxplore.png" />
        <img v-else-if="partnerInfo?.showPoweredText" w="14 md:20" self="end md:center" src="~assets/icons/azgoxplore.png" />
      </NuxtLink>
    </div>

    <div v-if="showCurrency" flex="~ items-center">
      <!-- H5 端 -->
      <template v-if="isMobile">
        <div class="cursor-pointer" flex="~ items-center" mr-3 @click="showLocalePopup = true">
          <span text="xs lg:xl white">
            {{ langH5Map[locale] }}
          </span>
          <NuxtIcon name="material-symbols:language" text="sm lg:3xl white" ml="0.5 lg:2.5" />
        </div>
        <div class="cursor-pointer" flex="~ items-center" mr-1 @click="showPopup = true">
          <span text="xs lg:xl white" class="align-middle">
            {{ currentCurrency }}
          </span>
          <span text="sm lg:3xl white" ml="0.5 lg:2.5" class="align-middle">$</span>
          <!-- <van-image :width="20" :height="20" :src="UserIcon" class="align-middle ml-2"></van-image> -->
        </div>
        <van-popup v-if="isMobile" v-model:show="showPopup" position="bottom" closeable safe-area-inset-bottom round>
          <div class="px-4 py-3 text-center align-middle text-lg font-500">
            {{ t('key.business.user.header.selectCurrency') }}
          </div>
          <div class="px-4">
            <div class="mb-3 text-[#121212] font-500">
              {{ t('key.business.user.header.allCurrency') }}
            </div>
            <van-radio-group v-model="currentCurrency" class="pb-4">
              <BaseAZRadio v-for="item in currencyInfo" :key="item.currency" :name="item.currency">
                <div class="flex items-center p-2 text-sm text-[#121212]">
                  <div class="w-[62px] text-base font-500">
                    {{ item.currency }}
                  </div>
                  <div class="text-base">
                    {{ item.fullName }}
                  </div>
                </div>
              </BaseAZRadio>
            </van-radio-group>
          </div>
        </van-popup>
        <van-popup v-if="isMobile && showLanguage" v-model:show="showLocalePopup" position="bottom" closeable safe-area-inset-bottom round>
          <div class="px-4 py-3 text-center align-middle text-lg font-500">
            {{ t('key.lang') }}
          </div>
          <div class="px-4 pb-4">
            <van-radio-group v-model="localeInfo" class="pb-4">
              <BaseAZRadio v-for="item in locales || []" :key="item" :name="item">
                <div class="flex items-center p-2 text-sm text-[#121212]">
                  <div class="w-[200px] text-base font-500">
                    {{ langPCMap[item] }}
                  </div>
                </div>
              </BaseAZRadio>
            </van-radio-group>
          </div>
        </van-popup>
      </template>
      <!-- PC 端 -->
      <div v-else flex="~ items-center nowrap" space-x="7">
        <BaseAZPopover v-if="showLanguage" v-model:open="showLocalePopup" align="end" :align-offset="-100" :side-offset="10">
          <template #reference>
            <div class="cursor-pointer" flex="~ items-center" @click.stop="showLocalePopup = !showLocalePopup">
              <span text="sm white" class="align-middle">
                {{ langPCMap[locale as string] }}
              </span>
              <NuxtIcon name="material-symbols:language" text="xl white" ml="1.5" class="align-middle" />
            </div>
          </template>
          <template #default>
            <div class="px-4 text-left align-middle text-lg font-500">
              {{ t('key.lang') }}
            </div>
            <div class="px-4 pt-4">
              <van-radio-group v-model="localeInfo" class="grid grid-cols-4 gap-x-4">
                <BaseAZRadio v-for="item in locales || []" :key="item" :name="item">
                  <div class="flex items-center p-2 pl-0 text-sm text-[#121212]">
                    <div class="mx-1 w-24 text-base font-500">
                      {{ langPCMap[item as string] }}
                    </div>
                  </div>
                </BaseAZRadio>
              </van-radio-group>
            </div>
          </template>
        </BaseAZPopover>
        <BaseAZPopover v-model:open="showPopup" align="end" :align-offset="-100" :side-offset="6">
          <template #reference>
            <div class="cursor-pointer" @click.stop="showPopup = !showPopup">
              <span text="sm white" class="align-middle">
                {{ currentCurrency }}
              </span>
              <span text="xl white" ml="1.5" class="align-middle">$</span>
            </div>
          </template>
          <template #default>
            <div class="px-4 text-left align-middle text-lg font-500">
              {{ t('key.business.user.header.selectCurrency') }}
            </div>
            <div class="px-4">
              <div class="mb-3 pt-4 text-[#121212] font-500">
                {{ t('key.business.user.header.allCurrency') }}
              </div>
              <van-radio-group v-model="currentCurrency" class="grid grid-cols-3 gap-x-4">
                <BaseAZRadio v-for="item in currencyInfo" :key="item.currency" :name="item.currency">
                  <div class="flex items-center p-2 pl-0 text-sm text-[#121212]">
                    <div class="mx-1 w-12 text-base font-500">
                      {{ item.currency }}
                    </div>
                    <div class="text-base">
                      {{ item.fullName }}
                    </div>
                  </div>
                </BaseAZRadio>
              </van-radio-group>
            </div>
          </template>
        </BaseAZPopover>
      </div>
      <ClientOnly>
        <NuxtLink v-if="supportDirectBooking" :to="{ path: `/search-bookings/${locale}.html`, query }">
          <div class="cursor-pointer" flex="~ items-center" text="xs md:sm white" m-l="1.5 md:8">
            <span v-if="!isMobile" m="r-1.5">{{ t('key.search.title') }}</span>
            <img src="~assets/icons/icon-search.png" h="4 md:5" w="4 md:5" alt="">
          </div>
        </NuxtLink>
      </ClientOnly>
    </div>
  </div>
</template>

<script setup lang="ts">
import { langH5Map, langPCMap } from '~/constant/languages'

defineProps({
  showCurrency: {
    type: Boolean,
    default: true,
  },
})
const { locale, changeLocale } = useLocale()
const { locales } = useNuxtApp().payload.data
const { t } = useI18n()

// const { query, name, params } = useRoute()
const route = useRoute()
const router = useRouter()
const isMobile = useMobileByScreen()

const showPopup = ref(false)
const showLocalePopup = ref(false)
const showLanguage = useRuntimeConfig().public.showLanguageSwitcher

const userInfoStore = useUserStore()
const commonDataStore = useCommonStore()
const supportDirectBooking = computed(() => !!commonDataStore.commonData?.partnerInfo?.supportDirectBooking)
const localeInfo = ref(locale)
const currencyInfo = computed(() => commonDataStore.commonData?.currencyInfo || [])
const partnerInfo = computed(() => commonDataStore.commonData?.partnerInfo)
const currentCurrency = computed({
  get: () => userInfoStore.userInfo.currency,
  set: (value: string) => {
    fetchChageCurrency(value)

    userInfoStore.userInfo.currency = value
    showPopup.value = false

    // 更新 URL 中的 currency 参数
    router.replace({
      query: {
        ...route.query,
        currency: value,
      },
    })
  },
})

// 返回首页时，清除 URL 中 无用 的 query 参数
const query = computed(() => ({
  ...route.query,
  locale: undefined,
  tab_key: undefined,
  serialNo: undefined,
  travelDate: undefined,
  query: undefined,
  attrId: undefined,
  currency: undefined,
  destinationId: undefined,
}))

function fetchChageCurrency(currency: string) {
  const { $fetch } = useNuxtApp()

  $fetch('/api/currency-change', {
    method: 'post',
    body: {
      currency,
    },
  })
}

const cobrand = computed(() => {
  const { partnerInfo } = commonDataStore.commonData || {}

  return {
    logo: partnerInfo?.distLogo,
    name: partnerInfo?.distName,
    logoLink: partnerInfo?.distLogoSkipUrl,
  }
})

const isPartner = computed(() => Boolean(route.query.campaign_id) && (cobrand.value.logo || cobrand.value.name))

watch(() => localeInfo.value, (value) => {
  showLocalePopup.value = false

  if (value) {
    changeLocale(value)
  }
})
</script>
