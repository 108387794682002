export default defineNuxtRouteMiddleware(async (to) => {
  if (/\/tc\/order-detail\/[\w!@#$%^&*()-]+\/[\w-]+/.test(to.path)) {
    try {
      const [token, orderNo] = to.path.split('/').slice(-2)
      const { NUXT_TC_BACKEND_URL, NUXT_TC_BACKEND_TOKEN } = useRuntimeConfig().public
      const data: any = await $fetch(`${NUXT_TC_BACKEND_URL}/attOrder/detail`, {
        body: {
          head: {
            token,
          },
          orderNo,
        },
        method: 'POST',
        headers: {
          'Labrador-Token': NUXT_TC_BACKEND_TOKEN as string,
          'Labrador-Passthrough': 'true',
          'Labrador-Tries': '1',
        },
      })
      const voucherUrl = data?.data?.orderVoucher?.voucherUrl
      if (voucherUrl) {
        return navigateTo(voucherUrl, {
          external: true,
        })
      }
    }
    catch (e) {
      console.error(e)
    }
  }

  if (/\/tc\/custom-service\/[\w-]+/.test(to.path)) {
    try {
      const [orderNo] = to.path.split('/').slice(-1)

      const customServiceUrl = `https://livechat.ly.com/out/chat?ProductId=130&SourceType=1&PageId=1300003&OrderSerialId=${orderNo}&FPage=${encodeURIComponent('/azgo_xpage/page/order_detail')}`

      return navigateTo(customServiceUrl, {
        external: true,
      })
    }
    catch (e) {
      console.error(e)
    }
  }
})
