<template>
  <div h="full" w="full" class="absolute" overflow-y="auto">
    <div p="y-1" :style="{ backgroundColor: 'var(--tc-color-bg-index)' }">
      <div max-w="300" m-x="auto" p="x-3">
        <img w="20 md:30" self="end md:center" src="~assets/icons/azgoxplore.png" />
      </div>
    </div>
    <slot />
    <!-- 底部介绍 -->
    <MobileFooter v-if="isMobile" />
    <DesktopFooter v-else />
  </div>
</template>

<script lang="ts" setup>
const isMobile = useMobileByScreen()

await useInitializeApp()
</script>

<style>

</style>
