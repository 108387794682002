import { attrRankController } from '~/apis/back/services'
import type { AttSpecialTopicConfigDTO, BaseEsPageResponseDTOAttSpecialTopicConfigDTO } from '~/apis/back/types'
import { ENUM_EventType, ENUM_PageName, E_RANK_TYPE } from '~/enums'
import type { SelectItemClickEvent, ViewItemListClickEvent } from '~/types/event-tracking'

export interface IFilterParams {
  freeCancellation?: number
  instantConfirmation?: number
  priceMin?: number
  priceMax?: number
}

interface PropsData {
  itemIndex?: number
  itemData: AttSpecialTopicConfigDTO
  rankType?: string
}

export function useExperienceList() {
  const { doExpSearch, confirmAttrList } = useSearchAttr()
  const { getRequestFilterConditions, resetDetailFilter, filterConditions } = useFilterConditionsStore()
  const { locale } = useLocale()
  const isMobile = useMobileByScreen()
  const route = useRoute()
  const { userInfo } = useUserStore()
  const gtm = useGtm()
  const dayjs = useDayjs()

  const filters = useState<IFilterParams>('azgo_experience_list_filter', () => ({
    freeCancellation: undefined,
    instantConfirmation: undefined,
    priceMin: undefined,
    priceMax: undefined,
  }))

  const state = useState<BaseEsPageResponseDTOAttSpecialTopicConfigDTO>('azgo_experience_list', () => ({}))
  const isLoading = useState('azgo_experience_list_loading', () => false)
  const isReady = useState('azgo_experience_list_ready', () => false)

  async function fetchSearchExperience() {
    if (route.query.query) {
      const searchResult = await doExpSearch(route.query.query as string, filters.value)
      confirmAttrList.value = {
        [E_RANK_TYPE.EXPERIENCE]: searchResult,
        [E_RANK_TYPE.ATTRACTIONS]: [],
      }
    }
  }

  async function fetchExperienceList() {
    isLoading.value = true
    isReady.value = false

    const { date, destinationId } = getRequestFilterConditions()

    const response = await attrRankController.rankPageAttSpecialTopicConfigPost({
      travelDate: date,
      cityId: destinationId,
      ...filters.value,
    })

    response.data.value?.data?.data?.forEach((item) => {
      item.cover = item.cover?.replace('http://', 'https://') || ''
      item.images = item.images?.map(image => ({
        ...image,
        imageUrl: image.imageUrl?.replace('http://', 'https://') || '',
      }))
    })

    state.value = response.data.value?.data || {}
    trackViewItemListEvent()

    // Simulate loading
    setTimeout(() => {
      isLoading.value = false
      isReady.value = true
    }, 200)
  }

  function resetExperienceFilter() {
    filters.value = {
      freeCancellation: undefined,
      instantConfirmation: undefined,
      priceMin: undefined,
      priceMax: undefined,
    }
  }

  function onFilterConfirm(filter: typeof filters.value) {
    filters.value = filter
  }

  function trackViewDealEvent(props: PropsData, isRankList = false) {
    const selectItemParams: SelectItemClickEvent = {
      event: ENUM_EventType.SelectItem,
      page_category: ENUM_PageName.List,
      prd_type: route.query.tab_key?.toString() === '2' ? 'Experiences' : 'All',
      city_id: filterConditions.selectedCity?.destinationId,
      city: filterConditions.selectedCity?.destinationName,
      country_code: filterConditions.selectedCity?.countryCode,
      locale: locale.value,
      search_date: dayjs(filterConditions.date).format('YYYY-MM-DD'),
      campaign_id: route.query.campaign_id as string ?? '',
      campaign_name: route.query.campaign_name as string ?? '',
      currency: userInfo.currency,
      experience_id: props.itemData.specialTopicSerialNo,
      experience_name: props.itemData.specialTopicName,
      experience_list_position: isRankList ? props.itemIndex || props.itemIndex === 0 ? props.itemIndex + 1 : -1 : undefined,
      experience_price: props.itemData.minPrice,
    }

    gtm?.trackEvent(selectItemParams)
  }

  function trackViewItemListEvent() {
    if (route.query.tab_key !== '2') {
      return
    }
    const params: ViewItemListClickEvent = {
      event: ENUM_EventType.ViewItemList,
      page_category: ENUM_PageName.List,
      locale: locale.value,
      city_id: filterConditions.selectedCity?.destinationId,
      city: filterConditions.selectedCity?.destinationName,
      country_code: filterConditions.selectedCity?.countryCode,
      campaign_id: route.query.campaign_id as string ?? '',
      campaign_name: route.query.campaign_name as string ?? '',
      search_date: dayjs(filterConditions.date).format('YYYY-MM-DD'),
      experience_number: state.value?.data?.length ?? 0,
      prd_type: 'Experiences',
    }
    console.log('ViewItemList2', params)
    gtm?.trackEvent(params)
  }

  function navigatorToExperienceDetail(props: PropsData, isRankList = false) {
    const filterCookie = useCookie('azgo-experience-filter')
    filterCookie.value = JSON.stringify(filters.value)
    resetDetailFilter()
    const routeOpts = {
      path: `/experience/detail/${makeExperienceDetailRoute()}`,
      query: {
        ...route.query,
        serialNo: props.itemData.specialTopicSerialNo,
        travelDate: dayjs(filterConditions.date).format('YYYY-MM-DD'),
        // 清除列表页搜索条件
        tab_key: undefined,
        query: undefined,
      },
    }
    trackViewDealEvent(props, isRankList)
    return navigateTo(routeOpts, {
      open: isMobile.value
        ? undefined
        : {
            target: '_blank',
          },
    })
  }

  return {
    state,
    filters,
    isLoading,
    isReady,
    resetExperienceFilter,
    onFilterConfirm,
    fetchExperienceList,
    fetchSearchExperience,
    navigatorToExperienceDetail,
  }
}
