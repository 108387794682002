<template>
  <div class="mx-auto h-full bg-white">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
await useInitializeApp()
useGlobalCssVars()
</script>

<style>
#__nuxt {
  height: 100%;
  background: #ffffff;
}
</style>
