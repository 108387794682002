import { defineStore } from 'pinia'
import { currencyController } from '~/apis/back/services'
import type { CurrencyDto, QueryDistributionInfoResponse, SearchFilterConfigDto } from '~/apis/back/types'
import { CountryCodeMap } from '~/constant/countries'
import type { AttrDestinationDto, PartnerCityListRes } from '~/server/types/city.type'
import type { IIndexConfigRes } from '~/server/types/config.type'

export interface IIndexConfig {
  /* 币种信息 */
  currencyInfo?: CurrencyDto[]
  /* 合作品牌信息 */
  brandInfo?: string[]
  /* 分销商信息 */
  partnerInfo?: QueryDistributionInfoResponse
  /* 城市列表 */
  cityInfo?: PartnerCityListRes
  /* 榜单页filter Options */
  filterOpitonsInfo?: SearchFilterConfigDto
}

export const useCommonStore = defineStore('common', () => {
  const commonData = reactive<IIndexConfig>({
    currencyInfo: undefined, // 币种列表信息
    brandInfo: [], // 品牌列表
    partnerInfo: undefined, // 分销商信息，包含分销商图片、里程信息
    cityInfo: {
      attrDestinationList: [],
      recommendDestinationList: [],
    }, // 城市列表、推荐城市列表
    filterOpitonsInfo: undefined, // 榜单页filter Options
  })

  const filterConditionsStore = useFilterConditionsStore()

  // 获取首页配置
  async function getIndexConfig() {
    // 如果已经获取过数据，就不再请求
    if (
      commonData.currencyInfo?.length
      && commonData.brandInfo
      && commonData.partnerInfo && commonData.filterOpitonsInfo
    ) {
      return
    }

    await fetchCommonData()
  }

  async function fetchCommonData() {
    const { data } = await useAppFetch<IIndexConfigRes>('/api/config', { method: 'POST' })

    if (data.value) {
      const { currencyInfo, brandInfo, partnerInfo, filterOpitonsInfo } = data.value as IIndexConfigRes

      commonData.currencyInfo = currencyInfo
      commonData.brandInfo = brandInfo
      commonData.partnerInfo = partnerInfo
      commonData.filterOpitonsInfo = filterOpitonsInfo

      if (commonData.filterOpitonsInfo?.filters?.length) {
        const hasAdult = commonData.filterOpitonsInfo.filters.some(
          item => item.category === '1001'
            && item.options?.some(opt => opt.optionCode === '1'),
        )
        if (hasAdult) {
          filterConditionsStore.filterConditions.defaultFilterIdList = ['1001_1']
          filterConditionsStore.filterConditions.filterIdList = filterConditionsStore.filterConditions.defaultFilterIdList
          filterConditionsStore.filterConditions.detailFilterIdList = filterConditionsStore.filterConditions.defaultFilterIdList
        }
      }
    }
  }

  async function updateInfoByLocale() {
    const { data } = await useAppFetch<IIndexConfigRes>('/api/config', { method: 'POST' })

    if (data.value) {
      const { partnerInfo, filterOpitonsInfo } = data.value as IIndexConfigRes

      commonData.partnerInfo = partnerInfo
      commonData.filterOpitonsInfo = filterOpitonsInfo
    }
  }

  function addCountryCode(countryList: AttrDestinationDto[]) {
    for (const country of countryList) {
      country.countryCode = CountryCodeMap[country.countryEName?.toLowerCase() ?? '']
    }
  }

  async function getCityList() {
    const { data } = await useAppFetch<PartnerCityListRes>('/api/city', { method: 'POST' })

    if (data.value) {
      const { attrDestinationList, recommendDestinationList } = data.value

      if (attrDestinationList) {
        addCountryCode(attrDestinationList)
      }

      if (recommendDestinationList) {
        addCountryCode(recommendDestinationList)
      }

      commonData.cityInfo = data.value
    }
  }

  const getCurrencySymbol = computed(() => {
    const userInfoStore = useUserStore()
    if (commonData.currencyInfo) {
      const target = commonData.currencyInfo?.find(
        item => item.currency === userInfoStore.userInfo.currency,
      )
      return target?.symbol || ''
    }
    return ''
  })

  // 更新货币信息
  async function updateCurrency() {
    const response = await currencyController.currencyListPost({})
    if (response.data.value?.data?.currencyList) {
      commonData.currencyInfo = response.data.value.data.currencyList
    }
  }

  return {
    commonData,
    updateCurrency,
    getIndexConfig,
    fetchCommonData,
    updateInfoByLocale,
    getCityList,
    getCurrencySymbol,
  }
})
