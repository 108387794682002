<template>
  <div max="h-150" w="150" bg="white" flex="~ col" m="x--5 b--5" rounded="2xl">
    <div class="search-container">
      <ElInput
        v-model="searchInput"
        width="100%"
        size="large"
        :clearable="true"
        :placeholder="t('key.search.destination.placeholder')"
      >
        <template #prefix>
          <ElIcon><ElIconSearch></ElIconSearch></ElIcon>
        </template>
      </ElInput>
    </div>
    <div v-if="!isSearching" p="x-5 b-5">
      <template v-if="history.length">
        <div text="base #121212" font="500">
          {{ t('key.search.destination.history') }}
        </div>
        <div class="flex flex-wrap" p="t-3 b-1">
          <div v-for="item in history" :key="item.destinationId" class="cursor-pointer" text="sm #646464" p="y-2 x-4" m="r-3 b-3" font="500" bg="#F3F3F3" rounded="10" @click="onConfirm(item)">
            {{ item.destinationName }}
          </div>
        </div>
      </template>

      <template v-if="cityInfo.recommendDestinationList?.length">
        <div class="pb-4 text-base text-[#121212] font-500">
          {{ t('key.search.destination.featured') }}
        </div>
        <div class="pr-6">
          <div class="flex flex-wrap gap-2.5 pb-2">
            <div
              v-for="city in cityInfo.recommendDestinationList"
              :key="city.destinationId"
              @click="onConfirm(city)"
            >
              <div
                b="~ 1 solid #E4E4E4"
                rounded="xl" w="24" p="1.5" text="center" cursor-pointer
                :title="city.destinationName"
              >
                <van-image
                  :src="city.img"
                  :width="80"
                  :height="80"
                  :radius="8"
                  class="rounded-xl"
                />
                <div class="line-clamp-1 mt-2 text-sm text-black font-500">
                  {{ city.destinationName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div v-else b-5="1 solid b-t #D9D9D9" p="y-4" max-h="100" overflow="y-auto">
      <template v-if="searchCityList.length">
        <template v-for="item in searchCityList" :key="item.destinationId">
          <div class="flex cursor-pointer px-4 py-2 hover:bg-[#F3F3F3]" @click="onConfirm(item)">
            <div h="11" w="11" flex="~ items-center justify-center" rounded="xl" bg="#f6f6f6">
              <img class="h-6 w-6" src="~assets/icons/icon_location.png" />
            </div>
            <div class="ml-2.5 flex flex-col justify-between py-0.5">
              <div
                class="text-base text-[#121212] font-500"
                v-html="item.destinationNameHTML"
              ></div>
              <div class="text-xs text-[#B2B2B2]" v-html="item.countryNameHTML"></div>
            </div>
          </div>
        </template>
      </template>
      <div v-else class="p-20 text-center text-base text-[#B2B2B2] font-500">
        {{ t('key.search.destination.emptyTips', [searchInput]) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ENUM_EventType, ENUM_PageName } from '~/enums'
import type { AttrDestinationDto, PartnerCityListRes } from '~/server/types/city.type'
import type { CityClicksClickEvent } from '~/types/event-tracking'

const props = withDefaults(defineProps<{
  refrehsOnConfirm?: boolean
  pageType?: string
}>(), {
  refrehsOnConfirm: true,
})

const emit = defineEmits<{
  (e: 'confirm'): void
}>()

const commonStore = useCommonStore()

const cityInfo = computed<PartnerCityListRes>(
  () =>
    commonStore.commonData.cityInfo || {
      attrDestinationList: [],
      recommendDestinationList: [],
    },
)

const { searchInput, history, searchCityList, isSearching, onConfirmCity } = useSearchCity({ refrehsOnConfirm: props.refrehsOnConfirm })

const { t } = useI18n()
const route = useRoute()
const gtm = useGtm()
const { locale } = useLocale()
const filterConditionStore = useFilterConditionsStore()

function onConfirm(item: AttrDestinationDto) {
  emit('confirm')

  onConfirmCity(item)
  trackCityClickEvent()
}
function trackCityClickEvent() {
  const params: CityClicksClickEvent = {
    event: ENUM_EventType.CityClick,
    page_category: props.pageType === 'home' ? ENUM_PageName.Home : ENUM_PageName.List,
    locale: locale.value,
    city_id: filterConditionStore.filterConditions.selectedCity?.destinationId,
    city: filterConditionStore.filterConditions.selectedCity?.destinationName,
    campaign_id: route.query.campaign_id as string ?? '',
    campaign_name: route.query.campaign_name as string ?? '',
    country_code: filterConditionStore.filterConditions.selectedCity?.countryCode,
  }
  gtm?.trackEvent(params)
}
</script>

<style lang="scss" scoped>
.search-container {
  margin-bottom: 10px;
  padding: 0 20px;
  .el-input :deep(.el-input__wrapper) {
    border-radius: 20px;
  }
}
</style>
