import type { UseFetchOptions } from '#app'
import { BACKEND_PREFIX } from '~/constant/request'

export function useAppFetch<T>(
  url: string,
  options: UseFetchOptions<T> = {},
) {
  const head = useCommonHead()
  return useFetch(url, {
    body: {
      head,
      ...(options?.body as object || {}),
    },
    ...options,
    onRequest({ options }) {
      const originalBody = options.body || {}
      const userInfoStore = useUserStore()
      const userInfo = userInfoStore.getRowUserInfo()
      if (typeof originalBody === 'object') {
        options.body = {
          ...originalBody,
          ...userInfo,
        }
      }
    },
  })
}

export function useBackendFetch<T>(
  url: string,
  options: UseFetchOptions<T> = {},
) {
  const head = useCommonHead()
  return useFetch(`${url.startsWith('http://') ? '' : BACKEND_PREFIX}${url}`, {
    ...options,
    body: {
      // 添加用户信息
      ...useUserStore().getRowUserInfo(),
      head,
      ...(typeof options.body === 'object' ? options.body : {}),
    },
  })
}
