<template>
  <div w="full" relative bg="white">
    <div
      class="h-60 w-full" flex="~ justify-center items-center" p="b-12"
      :style="{
        background: 'center center no-repeat',
        backgroundSize: 'cover',
        backgroundColor: 'var(--tc-color-bg-index)',
        backgroundImage: `url(${bgImg})`,
      }"
    >
      <div class="px-6 text-center text-white font-600">
        <div class="whitespace-pre-wrap text-4xl leading-normal" :style="{ color: 'var(--tc-color-title-index)' }">
          {{ pageTitle }}
        </div>
        <div v-if="subTitle" class="mt-3 whitespace-pre-wrap text-xl font-400" :style="{ color: 'var(--tc-color-subtitle-index)' }">
          {{ subTitle }}
        </div>
      </div>
    </div>
    <div max-w="300" m="x-auto">
      <!-- 搜索框 -->
      <DesktopHomeSearch @search="handleSearch" />

      <!-- 合作品牌 -->
      <BusinessBPartnerBrands />

      <!-- 热门目的地 -->
      <DesktopPopularDestinations />

      <!-- 如何赚取 -->
      <BusinessBHowToEarn />

      <!-- APP下载链接 -->
      <div v-if="partnerInfo?.pcShowDownload" p="x-6 b-4" mb="15">
        <div text="2xl #121212" font="600" mb="2">
          {{ t('key.app.download.title') }}
        </div>
        <div flex="~ items-end">
          <img mr="4" w="27" h="27" rounded="1/4" src="~/assets/logo.png" />
          <div flex="1">
            <van-rate
              v-model="rate"
              readonly
              allow-half
              color="var(--tc-color-star)"
              void-color="#e3e3e3"
              size="26"
              gutter="6"
              void-icon="star"
            />
            <div text="lg #121212" m="t-1" w="105" leading="tight">
              {{ t('key.app.download.desc') }}
            </div>
          </div>
          <NuxtLink to="https://applink.azgotrip.com/ZRpn/y389rc22" target="_blank" external mr="3">
            <img h="10" src="~assets/images/home/apple_download.png" />
          </NuxtLink>
          <NuxtLink to="https://applink.azgotrip.com/ZRpn/y389rc22" target="_blank" external mr="10">
            <img h="10" src="~assets/images/home/google_download.png" />
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ogImageUrl from '~/assets/logo.png'

const { t } = useI18n()

useSeoMeta({
  title: t('key.attractions.index.title'),
  description: t('key.attractions.index.descrition'),
  ogImageUrl,
})

const { pageTitle, subTitle, bgImg } = usePartnerConfig()
const { trackSearchClickEvent } = useTrackHome()

const commonDataStore = useCommonStore()
const partnerInfo = computed(() => commonDataStore.commonData?.partnerInfo)

function handleSearch() {
  trackSearchClickEvent()
}

const rate = ref(4.6)
</script>
