export enum ENUM_PageName {
  Home = 'Home Page',
  List = 'List Page',
  Detail = 'Details Page',
  Checkout = 'Checkout Page', // 填写页
  BookingConfirmation = 'Booking Confirmation Page', // 支付完成页
  BookingList = 'Booking List Page', // 订单列表页
  BookingDetail = 'Booking Details Page', // 订单详情页
  Search = 'Search Page',
}

// export enum ENUM_TicketTypeId {
//   Adult = 1,
//   Child,
//   Family,
//   TicketOnly,
//   TicketMore,
// }

export enum ENUM_EventType {
  Search = 'search',
  SelectItem = 'select_item',
  SearchItem = 'search_item',
  DownloadButtonClick = 'download_button_clicks',
  BookItem = 'book_item',
  ViewItemList = 'view_item_list',
  ViewItem = 'view_item',
  RankClick = 'rank_clicks',
  CityClick = 'city_clicks',
  SearchDateClick = 'search_date_clicks',
  GuestNumberClick = 'guest_number_clicks',
  PageView = 'Page View',
  BeginCheckout = 'begin_checkout', // 填写页点击go to payment按钮触发
  Purchase = 'purchase', // 支付完成页 完成支付成功触发
}

export enum ENUM_DetailBtnType {
  BigBtn = 'fromprice_button',
  SmallBtn = 'product_button',
}

export enum ENUM_BAND_CODE {
  /**
   * 成人
   */
  ADULT = 'ADULT',
  /**
   * 儿童
   */
  CHILD = 'CHILD',
  /**
   * 婴儿
   */
  INFANT = 'INFANT',
  /**
   * 老人
   */
  SENIOR = 'SENIOR',
  /**
   * 其他
   */
  OTHER = 'OTHER',
  /**
   * 标准定价（不区分人群）
   */
  STANDARD = 'STANDARD',
  /**
   * 学生
   */
  STUDENT = 'STUDENT',
  /**
   * 优待人群（军人、教师、残疾人等）
   */
  PREFERENTIAL = 'PREFERENTIAL',
}

export enum E_RANK_TYPE {
  ALL = '1000',
  ATTRACTIONS = '1101',
  EXPERIENCE = '2000',
}
