export interface ILocaleMessage {
  [locale: string]: {
    [i18nKey: string]: string
  }
}

const defaultLocale = 'en-US'

export function useLocale() {
  const cookie = useCookie('i18n_locale')
  const locale = useState('i18n_locale', () => cookie.value || defaultLocale)

  function getAvailableLocales(): string[] {
    return useNuxtApp().payload.data?.locales || []
  }

  function determineLocale(routeLocale: string, cookieLocale: string, availableLocales: string[]): string {
    if (routeLocale) {
      return routeLocale
    }
    if (cookieLocale && availableLocales.includes(cookieLocale)) {
      return cookieLocale
    }
    return defaultLocale
  }

  function watchLocale() {
    watchEffect(() => {
      const route = useRoute()
      const availableLocales = getAvailableLocales()
      const localeName = determineLocale(route.params.locale as string, cookie.value || '', availableLocales)
      locale.value = localeName

      // 默认语言不设置cookie
      if (cookie.value && cookie.value !== localeName) {
        cookie.value = localeName
      }
    })
  }

  function changeLocale(newLocale: string) {
    const availableLocales = getAvailableLocales()
    if (!availableLocales.includes(newLocale)) {
      return
    }
    const currentRoute = useRoute()
    const newRoute = {
      ...currentRoute,
      params: {
        ...currentRoute.params,
        locale: newLocale,
      },
    }
    locale.value = newLocale
    cookie.value = newLocale
    if (currentRoute.params.locale) {
      // 手动更新货币信息
      useCommonStore().updateCurrency()
      return navigateTo(newRoute)
    }
    else {
      setTimeout(() => {
        window.location.reload()
      }, 10)
    }
  }

  return {
    locale,
    cookie,
    watchLocale,
    changeLocale,
  }
}
