<template>
  <div w="full" min-h="full" relative bg="white">
    <div class="h-48 w-full flex flex-col" :style="{ backgroundColor: 'var(--tc-color-bg-index)' }">
      <div
        flex="~ 1 col items-center justify-center" text="center white xl"
        class="-pre-wrap px-6 pb-8 font-600 leading-tight"
        :style="{
          background: 'center center no-repeat',
          backgroundSize: 'cover',
        }"
      >
        <!-- <div class="flex-1 whitespace-pre-wrap text-xl font-600 leading-tight"> -->
        <div class="whitespace-pre-wrap">
          {{ coinsTitle }}
        </div>
        <div class="mt-3 whitespace-pre-wrap text-sm font-400">
          {{ coinsSubTitle }}
        </div>
        <!-- </div> -->
      </div>
    </div>

    <div class="position-relative px-2 pt-4.5 -mt-2">
      <MobileHomeSearchInCoins @search="handleSearch" />
    </div>

    <!-- 合作品牌 -->
    <BusinessBPartnerBrands />
    <van-divider p="x-8 y-2" />

    <!-- 如何赚取 -->
    <BusinessBHowToEarnInCoins />
  </div>
</template>

<script lang="ts" setup>
import { ENUM_EventType, ENUM_PageName } from '~/enums'
import type { SearchClickEvent } from '~/types/event-tracking'
import ogImageUrl from '~/assets/logo.png'

const { t } = useI18n()

useSeoMeta({
  title: t('key.coins.index.title'),
  description: t('key.coins.index.descrition'),
  ogImageUrl,
})

const gtm = useGtm()
const filterConditionsStore = useFilterConditionsStore()
const dayjs = useDayjs()
const { query } = useRoute()
const { locale } = useLocale()

const { coinsTitle, coinsSubTitle } = useUseBurnCoins()

function handleSearch() {
  const eventParams: SearchClickEvent = {
    event: ENUM_EventType.Search,
    page_category: ENUM_PageName.Home,
    city: filterConditionsStore.filterConditions.selectedCity?.destinationName,
    city_id: filterConditionsStore.filterConditions.selectedCity?.destinationId,
    country_code: filterConditionsStore.filterConditions.selectedCity?.countryCode,
    search_date: dayjs(filterConditionsStore.filterConditions.date).format('YYYY-MM-DD'),
    locale: locale.value,
    campaign_id: query.campaign_id as string ?? '',
    campaign_name: query.campaign_name as string ?? '',
  }

  gtm?.trackEvent(eventParams)
}
</script>
