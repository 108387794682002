export default async function () {
  const userStore = useUserStore()
  const commonStore = useCommonStore()

  const { query } = useRoute()

  const userInfo = userStore.userInfo

  userInfo.distCode = query.campaign_id as string || 'azgo'
  userInfo.memberId = (query.memberId || query.member_id) as string || ''
  userInfo.aid = query.aid as never || undefined
  userInfo.sid = query.sid as never || ''
  userInfo.ouid = query.ouid as never || ''

  if (query?.memberId && !userInfo.accessToken) {
    userStore.fetchLoginToken()
  }

  await commonStore.getIndexConfig()

  // 设置默认币种
  if (!userInfo.currency) {
    userInfo.currency = query.currency as string || commonStore.commonData.partnerInfo?.defaultCurrency || 'USD'
  }
}
